import { defineStore, storeToRefs } from 'pinia';
import { useProfileStore } from './profile';
import { useLoading } from '@/composables/loading';
import { computed, ref } from 'vue';
import { fetchFullProfile } from '@/api/profile';
import { fetchUserGames } from '@/api/game';

export const useGamesController = defineStore('gamesController', () => {
  const profileStore = useProfileStore();
  const { profile } = storeToRefs(profileStore);

  const { isLoading } = useLoading();

  const games = ref(null);
  const filter = ref(['challenges', 'games']);

  const isExistsMoreGames = ref(true);
  const fetchOptionsGames = ref({
    filter: '',
    limit: 50,
    offset: 0,
  });

  const filterValue = computed(() => {
    const filterStringValue = filter.value.join();
    const filterValue = ['challenges', 'games'].includes(filterStringValue) ? filterStringValue : '';

    return filterValue;
  });

  const filterGames = computed(() => {
    return {
      challenges: (game) => !game.guest_accepted && !game.is_finished,
      games: (game) => game.guest_accepted || game.is_finished,
    };
  });

  const fetchGames = async () => {
    isLoading.value = true;

    const { games: userGames, existsMore } = await getUserGames();

    isExistsMoreGames.value = existsMore;
    games.value = userGames;

    isLoading.value = false;
  };

  const getProfileKey = (game) => (game.creator === profile.value.id ? 'creator' : 'guest');

  const getUserGames = async () => {
    const { games: userGames, existsMore } = await fetchUserGames(fetchOptionsGames.value);
    const filter = filterValue.value;

    const availableGames = Object.values(userGames).reduce((acc, game) => {
      const isArchived = game[`is_${getProfileKey(game)}_archive`];

      if (isArchived) return acc;

      if (filter) {
        const isFilterGameFit = filterGames.value[filter](game);

        if (isFilterGameFit) {
          acc[game.id] = {
            ...game,
          };
        }
      } else {
        acc[game.id] = {
          ...game,
        };
      }

      return acc;
    }, {});

    const gamesKeys = Object.keys(availableGames);

    const requests = gamesKeys.map((key) => {
      const opponentKey = profile.value.id === userGames[key].creator ? 'guest' : 'creator';

      return fetchFullProfile(userGames[key][opponentKey]);
    });

    const responses = await Promise.allSettled(requests);

    responses.forEach(({ status, value }, index) => {
      availableGames[gamesKeys[index]].opponent = status === 'fulfilled' ? value : null;
    });

    return {
      existsMore,
      games: availableGames,
    };
  };

  return {
    isLoading,
    games,
    filter,
    isExistsMoreGames,
    fetchOptionsGames,
    filterValue,
    filterGames,
    fetchGames,
    getUserGames,
  };
});
