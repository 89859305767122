<template>
  <nav class="menu">
    <v-hover>
      <template #default="{ isHovering, props }">
        <router-link
          v-bind="props"
          :to="{ name: 'games' }"
          :class="isHovering ? 'text-black' : 'text-tertiary-darken'"
          class="d-flex flex-column justify-center align-center font-weight-bold"
        >
          <v-badge
            v-if="Object.keys(games ?? {})?.length"
            :content="Object.keys(games ?? {})?.length"
            color="red"
          >
            <v-icon
              size="24"
              icon="mdi-bell"
            />
          </v-badge>
          <v-icon
            v-else
            size="24"
            icon="mdi-bell"
          />
          Игры
        </router-link>
      </template>
    </v-hover>
    <v-hover>
      <template #default="{ isHovering, props }">
        <router-link
          v-bind="props"
          :to="{ name: 'opponents' }"
          :class="isHovering ? 'text-black' : 'text-tertiary-darken'"
          class="d-flex flex-column justify-center align-center font-weight-bold"
        >
          <v-icon
            size="24"
            icon="mdi-account-multiple"
          />
          Соперники
        </router-link>
      </template>
    </v-hover>
    <div class="relative">
      <v-btn
        class="new-game-button"
        @click="$emit('new-game')"
      >
        <v-icon
          size="32"
          color="white"
          icon="mdi-plus"
        />
      </v-btn>
    </div>
    <v-hover>
      <template #default="{ isHovering, props }">
        <router-link
          v-bind="props"
          :to="{ name: 'rating' }"
          :class="isHovering ? 'text-black' : 'text-tertiary-darken'"
          class="d-flex flex-column justify-center align-center font-weight-bold"
        >
          <v-icon
            size="24"
            icon="mdi-star"
          />
          Рейтинг
        </router-link>
      </template>
    </v-hover>
    <v-hover>
      <template #default="{ isHovering, props }">
        <router-link
          v-if="profile"
          v-bind="props"
          :to="{ name: 'profile', params: { id: profile.id } }"
          :class="isHovering ? 'text-black' : 'text-tertiary-darken'"
          class="d-flex flex-column justify-center align-center font-weight-bold"
        >
          <v-icon
            size="24"
            icon="mdi-account"
          />
          Профиль
        </router-link>
      </template>
    </v-hover>
  </nav>
</template>

<script setup>
import { defineEmits, onBeforeMount } from 'vue';
import { storeToRefs } from 'pinia';
import { useProfileStore } from '@/stores/profile';
import { useGamesController } from '@/stores/gamesController';

defineEmits(['new-game']);

const profileStore = useProfileStore();
const { profile } = storeToRefs(profileStore);

const gamesController = useGamesController();
const { games } = storeToRefs(gamesController);
const { fetchGames } = gamesController;

onBeforeMount(() => {
  if (!Object.keys(games.value ?? {}).length) {
    fetchGames();
  }
});
</script>

<style lang="scss" scoped>
.menu {
  display: grid;
  grid-template: auto / 1fr 1fr 64px 1fr 1fr;

  a {
    font-size: 0.76rem;
  }
}

.new-game-button {
  position: absolute;
  top: -16px;
  min-height: 64px;
  min-width: 64px;
  height: 64px;
  width: 64px;
  border-radius: 50%;
  background: linear-gradient(180deg, rgba(255, 249, 226, 0.3) 0%, rgba(251, 236, 233, 0) 100%), #ff7267;
  background-color: #ff7267;
}

@media (min-width: 576px) {
  .menu {
    a {
      font-size: 1rem;
    }
  }
}
</style>
