import axios from '@/plugins/axios';

const transformOpponents = (opponents) => {
  const transformedOpponents = opponents?.reduce((acc, opponent) => {
    acc[opponent.profile.id] = {
      ...opponent.profile,
      statistic: opponent.stats,
    };
    return acc;
  }, {});

  return transformedOpponents || {};
};

const transformThemes = (themes) => {
  const transformedThemes = themes?.reduce((acc, theme) => {
    acc[theme.id] = {
      ...theme,
    };
    return acc;
  }, {});

  return transformedThemes || {};
};

const transformGames = (games) => {
  const transformedGames = games?.reduce((acc, game) => {
    acc[game.id] = {
      ...game,
    };
    return acc;
  }, {});

  return transformedGames || {};
};

export const findOpponents = async (params) => {
  const response = await axios.post('/api/findOpponents', {
    exclude: true,
    sort: 'string',
    ...params,
  });

  const { results: opponents = [], exists_more: existsMore = false } = response?.data.response || {};

  const filteredOpponents = opponents.sort((current, next) => {
    // Достаём количество общих игр
    const currentGames = current.stats?.common_games_count || 0;
    const nextGames = next.stats?.common_games_count || 0;

    // 1. Сортировка по ботам (боты выше)
    if (current.profile.is_bot && !next.profile.is_bot) return -1;
    if (!current.profile.is_bot && next.profile.is_bot) return 1;

    // 2. Сортировка по количеству общих игр
    if (currentGames !== nextGames) {
      return nextGames - currentGames;
    }

    // 3. Сортировка по имени
    return current.profile.name.localeCompare(next.profile.name);
  });

  const transformedOpponents = transformOpponents(filteredOpponents);

  return {
    existsMore,
    opponents: transformedOpponents,
  };
};

export const findRandomOpponent = async () => {
  const response = await axios.get('/api/game/getRandomOpponent');
  const opponentId = response?.data.response.opponent_id;

  return opponentId;
};

export const fetchOpponents = async (params) => {
  const response = await axios.post('/api/getOpponents', params);

  const { results: opponents = [], exists_more: existsMore = false } = response?.data.response || {};
  const transformedOpponents = transformOpponents(opponents);

  return {
    existsMore,
    opponents: transformedOpponents,
  };
};

export const fetchThemes = async (params) => {
  const response = await axios.post('/api/game/findThemes', params);

  const { result: themes = [], exists_more: existsMore = false } = response?.data.response || {};
  const transformedThemes = transformThemes(themes);

  return {
    existsMore,
    themes: transformedThemes,
  };
};

export const fetchTheme = async (params) => {
  const response = await axios.post('/api/game/getTheme', params);
  return response?.data.response;
};

export const fetchUserGames = async (params) => {
  const response = await axios.post('/api/getUserGames', params);

  const { results: games = [], exists_more: existsMore = false } = response?.data.response || {};
  const transformedGames = transformGames(games);

  return {
    existsMore,
    games: transformedGames,
  };
};

export const getGame = async (id) => {
  const response = await axios.get(`/api/game/getGame?id=${id}`);
  const game = response?.data.response || null;

  return game;
};

export const acceptGame = async (params) => {
  const response = await axios.post('/api/game/accept', params);
  const isSuccessAccepted = response?.data.response;

  return isSuccessAccepted;
};

export const cancelGame = async (params) => {
  const response = await axios.post('/api/game/decline', params);
  const isSuccessСanceled = response?.data.response;

  return isSuccessСanceled;
};

export const setThemeRound = async (params) => {
  const response = await axios.post('/api/game/setTheme', params);
  const round = response?.data.response;

  return round;
};

export const startGame = async (params) => {
  const response = await axios.post('/api/game/challenge', params);
  const game = response?.data.response || null;

  return game;
};

export const submitQuestion = async (params) => {
  const response = await axios.post('/api/game/submitQuestion', params);
  const isSuccessSubmited = response?.data.response;

  return !!isSuccessSubmited;
};

export const submitRound = async (params) => {
  const response = await axios.post('/api/game/submitRound', params);
  const isSuccessSubmited = response?.data.response;

  return !!isSuccessSubmited;
};

export const addGameToArchive = async (params) => {
  const response = await axios.post('/api/game/archive', params);
  const isSuccessArchived = response?.data.response;

  return isSuccessArchived;
};

export const getInviteLink = async (params) => {
  const response = await axios.post('/api/game/inviteByLink', params);
  const link = response?.data.response || null;

  return link;
};
