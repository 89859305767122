import { ref, computed } from 'vue';
import { defineStore, storeToRefs } from 'pinia';

import { useProfileStore } from '@/stores/profile';

export const useGameStore = defineStore('game', () => {
  const game = ref(null);
  const questionNumber = ref(null);
  const opponent = ref(null);

  const answer = ref(null);

  const timer = ref(null);
  const currentTime = ref(30000);

  const isActiveOpponentSelection = ref(false);
  const isActiveThemeSelection = ref(false);
  const isActiveInviteLinkSection = ref(false);

  const opponents = ref(null);
  const isExistsMoreOpponents = ref(false);
  const fetchOptionsOpponents = ref({
    limit: 50,
    name: '',
    offset: 0,
  });

  const theme = ref(null);

  const themes = ref(null);
  const isExistsMoreThemes = ref(false);
  const fetchOptionsThemes = ref({
    limit: 50,
    name: '',
    offset: 0,
  });

  const profileStore = useProfileStore();
  const { profile } = storeToRefs(profileStore);

  const profileKey = computed(() => (game.value?.creator === profile.value.id ? 'creator' : 'guest'));
  const opponentKey = computed(() => (profileKey.value === 'creator' ? 'guest' : 'creator'));

  const themeSelectionParams = computed(() => ({
    game_id: game.value.id,
    round_num: game.value.current_round,
    theme_id: theme.value.id,
    user_id: profile.value.id,
  }));

  const isValidGame = computed(() => [game.value?.creator, game.value?.guest].includes(profile.value.id));

  const round = computed(() => game.value?.rounds[game.value?.current_round - 1]);

  const currentQuestionNumber = computed(() => {
    if (questionNumber.value) return questionNumber.value;

    return round.value?.[`${profileKey.value}_answers`].length;
  });

  const questions = computed(() => round.value?.questions);

  const question = computed(() => questions.value?.[currentQuestionNumber.value]);

  const isNextQuestion = computed(() => !!questions.value?.[currentQuestionNumber.value + 1]);

  const timeToDisplay = computed(() => {
    const seconds = Math.floor((currentTime.value / 1000) % 60);
    const minutes = Math.floor((currentTime.value / 1000 / 60) % 60);

    return `${('0' + minutes).slice(-2)}:${('0' + seconds).slice(-2)}`;
  });

  const answerParams = computed(() => ({
    answer: answer.value?.isCorrect,
    game_id: game.value?.id,
    user_id: profile.value.id,
  }));

  const newGameParams = computed(() => ({
    creator_id: profile.value?.id ?? '',
    guest_id: opponent.value?.id ?? '',
    theme_id: theme.value.id ?? '',
  }));

  const startTimer = (time) => {
    currentTime.value = time;

    timer.value = setInterval(() => {
      currentTime.value > 0 ? (currentTime.value -= 1000) : stopTimer();
    }, 1000);
  };

  const stopTimer = () => {
    clearTimeout(timer.value);
  };

  const resetSelectOpponent = () => {
    opponents.value = null;
    opponent.value = null;
    isExistsMoreOpponents.value = false;
    fetchOptionsOpponents.value.offset = 0;
  };

  const resetSelectTheme = () => {
    theme.value = null;
    themes.value = null;
    isExistsMoreThemes.value = false;
    fetchOptionsThemes.value.offset = 0;
  };

  const resetGame = () => {
    game.value = null;
    answer.value = null;
    opponent.value = null;
  };

  return {
    game,
    isValidGame,
    profileKey,
    opponentKey,
    round,
    theme,
    questionNumber,
    currentQuestionNumber,
    questions,
    question,
    answer,
    isNextQuestion,
    opponent,
    opponents,
    isExistsMoreOpponents,
    fetchOptionsOpponents,
    fetchOptionsThemes,
    themes,
    isExistsMoreThemes,
    currentTime,
    timeToDisplay,
    answerParams,
    newGameParams,
    themeSelectionParams,
    timer,
    isActiveOpponentSelection,
    isActiveThemeSelection,
    isActiveInviteLinkSection,
    startTimer,
    stopTimer,
    resetSelectTheme,
    resetSelectOpponent,
    resetGame,
  };
});
